import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Space,
  DatePicker,
  Select,
  message,
  Image,
  Spin,
  Radio
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
// import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import Layout from '@/common/Layout';
import truck from '../../images/truck-delivery-done.gif';
// import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
const { RangePicker } = DatePicker;
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import { getPoNumbers } from '../../redux/slice';

const StockReportPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { allMaterials } = useSelector((state) => state.stocks);
  const [truckShow, setTruckShow] = useState(false);
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [futerdate, setFuterdate] = useState(true);
  const [search, setSearch] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [type, setType] = useState('');
  const [subType, setSubType] = useState('');
  const [poNumbers, setPoNumbers] = useState([]);
  // const [code, setCode] = useState('');

  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetAllPONumbers();
  }, [search, subType]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  const handleGetAllPONumbers = () => {
    let payload = { type: subType, search };
    dispatch(getPoNumbers(payload)).then((res) => {
      if (res?.payload?.success) {
        setPoNumbers(res?.payload?.data);
      }
    });
  };

  const onFinish = (values) => {
    const fromDate = values.fromDate
      ? `${moment(values.fromDate[0]._d).format('YYYY-MM-DD')}`
      : '';
    const toDate = values.fromDate
      ? `${moment(values.fromDate[1]._d).format('YYYY-MM-DD')}`
      : '';

    const user = LocalStorage.getItem('Gravity_user');
    setTruckShow(true);
    const authToken = user?.token;
    let url = '';
    if (values.report === 'invoice_tracker') {
      url =
        subType === 'date_range'
          ? `${process.env.REACT_APP_API_URL}/other-reports?report_type=${values.report}&from_date=${fromDate}&to_date=${toDate}`
          : `${process.env.REACT_APP_API_URL}/other-reports?report_type=${values.report}&type=${subType}&search=${materialId}`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/other-reports?report_type=${values.report}&from_date=${fromDate}&to_date=${toDate}&material_id=${materialId}`;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `Bearer ${authToken}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${values.report}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success('Report Downloaded successfully');
        form.resetFields();
        setTruckShow(false);
        // setDownloadReports(false);
      });
  };

  const handleChange = (e) => {
    setType(e);
    if (e === 'retest_material_report' || e === 'expiry_material_report') {
      setFuterdate(false);
    } else {
      setFuterdate(true);
    }
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onChange = (value) => {
    setMaterialId(value);
  };

  const onChangeType = (e) => {
    console.log(e);
    setSubType(e.target.value);
  };

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title={'DOWNLOAD OTHER REPORTS'} className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={6} span={12} md={24} sm={24}>
              <FormItem
                label="Stock Statement"
                name="report"
                rules={[
                  {
                    required: true,
                    message: 'Select Statement'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Please Select Statement"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="stock_reconsiliation">
                    Stock Reconsiliation
                  </option>
                  <option value="wastage_material_transactions_stock">
                    Wastage Material
                  </option>
                  <option value="retest_material_report">
                    Retest Material
                  </option>
                  <option value="expiry_material_report">
                    Expiry Material
                  </option>
                  <option value="damage_report">Damage Report</option>
                  <option value="non_moving_type">Non-Moving Stock</option>
                  <option value="material_stock_report">
                    Material Stock Report
                  </option>
                  <option value="invoice_tracker">
                    Invoice Tracker Report
                  </option>
                </Select>
              </FormItem>
            </Col>
            {
              <>
                {type === 'invoice_tracker' && (
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <FormItem
                      label="Select Type"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Select date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group onChange={onChangeType}>
                        <Radio value="po_num">PO No.</Radio>
                        <Radio value="invoice_num">Invoice No.</Radio>
                        <Radio value="date_range">Date</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                )}
                {subType === 'po_num' ? (
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Enter PO Number"
                      name="po_no"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Select date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a PO number"
                        optionFilterProp="children"
                        onChange={(e) => onChange(e, 'id')}
                        onSearch={onSearch}
                      >
                        {poNumbers ? (
                          poNumbers?.map((item, index) => {
                            return (
                              <option value={item.po_no} key={index}>
                                {item.po_no}
                              </option>
                            );
                          })
                        ) : (
                          <Spin />
                        )}
                      </Select>
                    </FormItem>
                  </Col>
                ) : subType === 'invoice_num' ? (
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Enter Invoice Number"
                      name="inv_no"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Select date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a Invoice number"
                        optionFilterProp="children"
                        onChange={(e) => onChange(e, 'id')}
                        onSearch={onSearch}
                      >
                        {poNumbers ? (
                          poNumbers?.map((item, index) => {
                            return (
                              <option value={item.invoice_no} key={index}>
                                {item.invoice_no}
                              </option>
                            );
                          })
                        ) : (
                          <Spin />
                        )}
                      </Select>
                    </FormItem>
                  </Col>
                ) : (
                  subType === 'date_range' && (
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="Select start and end date"
                        name="fromDate"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Select date'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <RangePicker
                          format={'YYYY-MM-DD'}
                          onChange={(x) => {
                            setDateRange(x);
                          }}
                          disabledDate={
                            futerdate
                              ? (current) => {
                                  return (
                                    current && current.valueOf() > Date.now()
                                  );
                                }
                              : false
                          }
                          value={dateRange}
                          // separator={'-'}
                          style={{ width: '100%' }}
                        />
                      </FormItem>
                    </Col>
                  )
                )}
                {type !== 'invoice_tracker' && (
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Select start and end date"
                      name="fromDate"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Select date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <RangePicker
                        format={'YYYY-MM-DD'}
                        onChange={(x) => {
                          setDateRange(x);
                        }}
                        disabledDate={
                          futerdate
                            ? (current) => {
                                return (
                                  current && current.valueOf() > Date.now()
                                );
                              }
                            : false
                        }
                        value={dateRange}
                        // separator={'-'}
                        style={{ width: '100%' }}
                      />
                    </FormItem>
                  </Col>
                )}
                {type === 'material_stock_report' && (
                  <Col xs={24} xl={6} span={6} md={24} sm={24}>
                    <FormItem
                      label="Material Code"
                      name="material_id"
                      rules={[
                        {
                          required: true,
                          message: 'Select Material Code'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a material code"
                        optionFilterProp="children"
                        onChange={(e) => onChange(e, 'id')}
                        onSearch={onSearch}
                      >
                        {allMaterials ? (
                          allMaterials?.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.code}
                              </option>
                            );
                          })
                        ) : (
                          <Spin />
                        )}
                      </Select>
                    </FormItem>
                  </Col>
                )}
              </>
            }
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : (
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Download
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          )}
        </FormItem>
      </Form>
    </Layout>
  );
};
export default StockReportPage;
