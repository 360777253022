import React, { useEffect, useState } from 'react';
import {
  Form,
  Card,
  Row,
  Col,
  Input,
  // DatePicker,
  // InputNumber,
  message,
  Space,
  Tooltip,
  Radio
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllBatcheslist,
  // createStockIssuance,
  batchUpdate,
  // getAllRemovedStock,
  createAllRemovedStock,
  RemovedStockUpdate,
  approveAllRemovedStock,
  rejectAllRemovedStock,
  downloadPdfFile
} from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { getLoggedInUser } from 'utilities/helpers';
import CustomModal from '@/common/CustomModal';
import { history } from '@/app/history';
import { EditOutlined, CloudDownloadOutlined } from '@ant-design/icons';
// import { useLocation } from 'react-router-dom';

const StockCardIssuancePage = () => {
  const [form] = Form.useForm();
  const [modalform] = Form.useForm();
  const params = useParams();
  const { allBatches } = useSelector((state) => state.issuance);
  const { user } = getLoggedInUser();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  // const { SuperadminProddata } = getRoles('Production');
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  // const [allBatches1, setAllBatches1] = useState(null);
  //const [isEditMode, setIsEditMode] = useState(false);
  // const [rejectedId, setRejectedId] = useState();
  // const searchParam = useLocation().search;
  // const searchParams = new URLSearchParams(searchParam);

  const dispatch = useDispatch();

  const [closingBal, setClosingBal] = useState(0);
  const [status, setStatus] = useState('');
  const [isModal, setIsModal] = useState(false);

  console.log(status);
  // const [code, setCode] = useState('');
  // const [batches, setBatches] = useState([]);
  console.log('all Batches: ', allBatches);
  useEffect(() => {
    handleGetAllBatchesList();
  }, [params.id]);

  const modalTitles = [
    {
      Approve: 'Are you sure you want to Approve the material?',
      OnHold: 'Are you sure you want to Hold the material?',
      Rejected: 'Are you sure you want to reject/dispose the material?',
      Release: 'Are you sure you want to release the material?'
    }
  ];

  const handleApproveCancel = () => {
    setIsApproveModalVisible(false);
  };

  const handleGetAllBatchesList = () => {
    let payload = { batch_id: params.id };
    dispatch(getAllBatcheslist(payload)).then((response) => {
      if (response?.payload?.success) {
        setClosingBal(
          response?.payload?.data?.stock_card_issuance?.length
            ? response?.payload?.data?.stock_card_issuance[0]?.closing_balance
            : 0
        );
      }
    });
    // dispatch(getAllRemovedStock(payload)).then((response) => {
    //   if (response?.payload?.success) {
    //     const removedStockData = response?.payload?.data;

    //     // Assuming the response contains a `removed_stock` array:
    //     const updatedRemovedStock = removedStockData?.removed_stock || [];
    //   }
    // });
  };
  const handleClose = () => {
    setIsModalVisible(false);
  };

  const downloadPdf = () => {
    let payload = { batch_details_id: params.id };
    dispatch(downloadPdfFile(payload)).then((response) => {
      if (response?.payload?.success) {
        window.open(`${response?.payload?.data}`);
      }
    });
  };

  const handleOpenModal = (type) => {
    setIsModal(true);
    setStatus(type);
    // onFinish2();
    setStatus(type);
    // setIsApproveModalVisible(true);
  };

  const handleApproveOpenModal = (type) => {
    // setIsModal(true);
    // setStatus(type);
    // onFinish2();
    setStatus(type);
    setIsApproveModalVisible(true);
  };

  console.log('All Batches1: ', allBatches);

  const onFinishModal = (values) => {
    console.log(values);
    const payload = {
      status: status === 'Release' ? values.status : status,
      batch_details_id: params.id,
      comments: values?.comments
    };
    dispatch(batchUpdate(payload)).then((response) => {
      if (response?.payload?.success) {
        handleGetAllBatchesList();
        setIsModal(false);
        message.success(response?.payload?.message);
        history.push('/stock-card');
      }
    });
    dispatch(RemovedStockUpdate(payload)).then((response) => {
      if (response?.payload?.success) {
        handleGetAllBatchesList();
        setIsModal(false);
        message.success(response?.payload?.message);
        history.push('/stock-card');
      }
    });
  };

  // const handleApproveClick = () => {

  //   form.submit();
  //   onFinish2();
  //   console.log("Approved");

  //   setIsApproveModalVisible(false);
  // };

  const onFinish = (values) => {
    console.log('Values Fetched: ', values);
    let payload = {
      ar_number: values?.ar_number,
      retest_date: values?.retest_date,
      approved_on: values?.approved_on,
      approved_quantity: allBatches?.received_quantity,
      closing_balance: values?.quantity_issued
        ? allBatches?.stock_card_issuance?.length
          ? parseFloat(closingBal) - parseFloat(values?.quantity_issued)
          : parseFloat(allBatches?.received_quantity) -
              parseFloat(
                allBatches?.sampling_history?.length
                  ? allBatches?.sampling_history[0]?.quantity
                  : 0
              ) -
              parseFloat(values?.quantity_issued) || 0
        : values?.quantity_returned
        ? allBatches?.stock_card_issuance?.length
          ? parseFloat(closingBal) + parseFloat(values?.quantity_returned)
          : parseFloat(closingBal) +
            parseFloat(values?.quantity_returned) +
            parseFloat(allBatches?.received_quantity)
        : allBatches?.received_quantity,
      remarks1: values?.remarks1,
      transaction_date: values?.transaction_date,
      quantity_issued: values?.quantity_issued,
      quantity_returned: values?.quantity_returned,
      returned_quantity_batch_number: values?.returned_quantity_batch,
      mrn_number: allBatches?.mrn_number,
      transaction_done_by: user?.id,
      remarks2: values?.remarks2,
      product_batch_no: values?.product_batch_number,
      batch_details_id: params?.id,
      materials_id: allBatches?.material_id,
      removed_qty: values?.removed_qty,
      removed_remarks: values?.removed_remarks || ''
      //id: params?.id,
      // materials_id: allBatches?.materials_id,
      // batch_details_id: allBatches?.batch_details_id,
      // removed_qty: allBatches?.removed_qty,
      // removed_remarks: allBatches?.removed_remarks||''
    };

    console.log('payload1: ', payload);
    //console.log('batchid: ', batch_details_id)
    // dispatch(createStockIssuance(payload)).then((response) => {
    //   if (response?.payload?.success) {
    //     message.success(response?.payload?.message);
    //     let payload = { batch_id: params.id };
    //     setIsModalVisible(false);

    //     dispatch(getAllBatcheslist(payload)).then((response) => {
    //       if (response?.payload?.success) {
    //         setClosingBal(
    //           response?.payload?.data?.stock_card_issuance?.length
    //             ? response?.payload?.data?.stock_card_issuance[0]
    //                 ?.closing_balance
    //             : 0
    //         );
    //       }
    //     });
    //     form.resetFields();
    //   }
    // });

    dispatch(createAllRemovedStock(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        //let payload = { list_details_id: user?.id };
        setIsModalVisible(false);
        form.resetFields();
      }
    });
  };
  // console.log("mainuserid: ", allBatches?.removed_stock?.batch_details_id)
  // console.log("AllBatches: ", allBatches)
  // const onFinish1 = () => {
  //   const values = form.getFieldsValue()

  //   const selectedBatch = Array.isArray(allBatches)
  //     ? allBatches.find(batch => batch.batch_details_id === params?.id)
  //     : null;

  //   let payload = {
  //     // id: params?.id,
  //     //materials_id:allBatches[0]?.materials_id,
  //     materials_id: allBatches?.material_id,
  //     batch_details_id: params?.id,
  //     removed_qty: values?.removed_qty,
  //     removed_remarks: values?.removed_remarks || '',
  //     //console.log('batchid: ',batch_details_id),
  //   };
  //   console.log("payload 123: ", payload)
  //   console.log("materials Id: ", allBatches?.material_id)
  //   //console.log("Materials Id: ",materials_id)
  //   // console.log("All Batches: ", allBatches)
  //   // console.log("payload: ", payload)
  //   // console.log("Removed Quantity: ", payload.removed_qty)

  //   dispatch(createAllRemovedStock(payload)).then((response) => {
  //     if (response?.payload?.success) {
  //       message.success(response?.payload?.message);
  //       //let payload = { list_details_id: user?.id };
  //       setIsModalVisible(false);

  //       dispatch(getAllRemovedStock(payload)).then((response) => {
  //         if (response?.payload?.success) {
  //           const removedStockData = response?.payload?.data;

  //           const updatedRemovedStock = removedStockData?.removed_stock || {};
  //         }
  //       });
  //       form.resetFields();
  //     }
  //   });
  // }

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const payload = {
      id: allBatches?.removed_stock?.[0]?.id,
      qa_remarks: values?.qa_remarks,
      status: status
    };
    console.log('id: ', payload.id);
    console.log('payload qa_remarks: ', payload);
    dispatch(approveAllRemovedStock(payload)).then(function (response) {
      console.log('Response: ', response);
      if (response?.payload?.success) {
        //history.push('/stock-card');
      }
      setIsApproveModalVisible(false);
    });
  };

  //   console.log("All Batches removed Stock: ",allBatches?.removed_stock?.id)
  //   const removedStockIds = allBatches?.removed_stock?.map(stock => stock?.id) || [];
  // console.log("Every Id Is Available here: ",removedStockIds);

  console.log('param.id:: ', params?.id);
  const handleReject = () => {
    const values = form.getFieldsValue();
    const payload = {
      id: allBatches?.removed_stock?.[0]?.id,
      qa_remarks: values?.qa_remarks,
      status: 'Rejected'
    };
    console.log('Payload Id :', payload.id);
    dispatch(rejectAllRemovedStock(payload)).then(function (response) {
      console.log('Response: ', response);
      if (response?.payload?.success) {
        //setRejectedId(id);
        //console.log('Reject button clicked with id:', id);
        message.success(response?.payload?.message);
        history.push('/stock-card');

        //setStatus(type);
        //setIsEditMode(true);
      }
      // setIsRejectModalVisible(false);
      //setIsApproveModalVisible(false);
    });
    setStatus(payload?.status);
  };
  console.log('status of payload: ', status);

  // const onFinish2 = () => {
  //   const values = form.getFieldsValue();
  //   let payload = {
  //     id: params?.id,
  //     status: status,
  //   };

  //   if (status === 'Approved') {
  //     dispatch(approveAllRemovedStock(payload)).then((response) => {
  //       if (response?.payload?.success) {
  //         message.success(response?.payload?.message);
  //         setIsModalVisible(false);
  //         form.resetFields();
  //       }
  //     });
  //   } else if (status === 'Rejected') {
  //     dispatch(rejectAllRemovedStock(payload)).then((response) => {
  //       if (response?.payload?.success) {
  //         message.success(response?.payload?.message);
  //         setIsModalVisible(false);
  //         form.resetFields();
  //       }
  //     });
  //   }
  // };

  // console.log('rejectedid 1209: ', rejectedId);

  console.log(parseFloat(closingBal));
  const columns = [
    {
      title: 'Date',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.transaction_date
          ? moment(record?.transaction_date).format('DD MMM YYYY')
          : 'NA';
      }
    },
    {
      title: 'Product Batch #',
      dataIndex: 'product_batch_no',
      key: 'product_batch_no',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Closing Qty',
      dataIndex: 'closing_balance',
      key: 'closing_balance',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Issued Qty',
      dataIndex: 'quantity_issued',
      key: 'quantity_issued',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Returned Qty',
      dataIndex: 'quantity_returned',
      key: 'quantity_returned',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'Returned Qty Batch #',
      dataIndex: 'returned_quantity_batch_number',
      key: 'returned_quantity_batch_number',
      ellipsis: true,
      width: '100px',
      align: 'center',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'MRN #',
      dataIndex: 'mrn_number',
      key: 'mrn_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.mrn_number || 'NA';
      }
    },
    {
      title: 'Type',
      dataIndex: 'transaction_type',
      key: 'transaction_type',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Done By',
      dataIndex: 'transaction_done_by',
      key: 'transaction_done_by',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.transaction_done_by || 'NA';
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks1',
      key: 'remarks1',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.remarks1 || 'NA';
      }
    }
  ];

  // var issued_qty = 0;
  // allBatches?.stock_card_issuance?.length
  //   ? allBatches?.stock_card_issuance.map((item) => {
  //       issued_qty += parseFloat(item?.quantity_issued || 0);
  //     })
  //   : (issued_qty = 0);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Card
        title={<span>MATERIAL STOCK CARD ISSUANCE DETAILS</span>}
        extra={
          <Space>
            <Tooltip
              placement="top"
              title={'Material Stock Card Issuance Details'}
            >
              <CustomButton
                onClick={() => downloadPdf()}
                className={styles.qrcode}
                disabled={params.id == '' ? true : false}
              >
                <CloudDownloadOutlined />
              </CustomButton>
            </Tooltip>
            <CustomButton
              type="primary"
              onClick={() =>
                history.push(`/detailed-dispense-list/${params.id}`)
              }
            >
              Dispensing Details
            </CustomButton>
          </Space>
        }
        className={`${styles.cardStyles_approver}`}
      >
        <Row gutter={24}>
          <Col xs={24} xl={4} span={12} md={24} sm={24}>
            <FormItem label="Material Code" className={styles.customLable}>
              <Input disabled value={allBatches?.material?.code || 'NA'} />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={8} md={24} sm={24}>
            <FormItem
              label="Material Name"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input value={allBatches?.material?.name || 'NA'} disabled />
            </FormItem>
          </Col>
          <Col xs={24} xl={4} span={12} md={24} sm={24}>
            <FormItem
              label="In House Batch #"
              rules={[
                {
                  required: true,
                  message: 'Select In House Batch Number'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                disabled
                value={allBatches?.inhouse_batch_number || 'NA'}
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={4} span={8} md={24} sm={24}>
            <FormItem
              label="UOM"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input value={allBatches?.material?.uom || 'NA'} disabled />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={8} md={24} sm={24}>
            <FormItem label="Manufacturer Name" className={styles.customLable}>
              <Input
                value={allBatches?.supplier?.manufacturer_name || 'NA'}
                disabled
              />
            </FormItem>
          </Col>

          {/* <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem label="Approved Quantity" className={styles.customLable}>
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Enter Approved Quantity"
                value={
                  parseFloat(allBatches?.received_quantity) -
                  parseFloat(
                    allBatches?.sampling_qty?.length
                      ? allBatches?.sampling_qty[0]?.total_value
                      : 0
                  )
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Closing Balance (As on Date)"
              className={styles.customLable}
            >
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Enter Closing Balance"
                disabled
                value={
                  parseFloat(allBatches?.received_quantity || 0) -
                  parseFloat(allBatches?.sampling_qty?.[0]?.total_value || 0) +
                  parseFloat(
                    allBatches?.stock_reconsiliation?.[0]?.total_reconcile || 0
                  ) -
                  parseFloat(
                    allBatches?.wastage_quantity?.[0]?.total_wastage || 0
                  ) +
                  parseFloat(allBatches?.stock_returned_qty || 0) -
                  issued_qty
                }
              />
            </FormItem>
          </Col> */}
          <Col xs={24} xl={4} span={12} md={24} sm={24}>
            <FormItem label="Mfg Date" className={styles.customLable}>
              <Input
                value={
                  allBatches?.mfg_date
                    ? moment(allBatches?.mfg_date).format('DD MMM YYYY')
                    : 'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem label="In-House Expiry" className={styles.customLable}>
              <Input
                value={
                  allBatches?.in_house_expiry_date
                    ? moment(allBatches?.in_house_expiry_date).format(
                        'DD MMM YYYY'
                      )
                    : 'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={4} span={12} md={24} sm={24}>
            <FormItem label="Mfg Expiry" className={styles.customLable}>
              <Input
                value={
                  allBatches?.mfg_expiry_date
                    ? moment(allBatches?.mfg_expiry_date).format('DD MMM YYYY')
                    : 'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={4} span={12} md={24} sm={24}>
            <FormItem label="Mfg Retest Date" className={styles.customLable}>
              <Input
                value={
                  allBatches?.mfg_retest_date
                    ? moment(allBatches?.mfg_retest_date).format('DD MMM YYYY')
                    : 'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem label="Suppliers Name" className={styles.customLable}>
              <Input value={allBatches?.supplier?.name || 'NA'} disabled />
            </FormItem>
          </Col>

          {/* <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem label="AR Number" className={styles.customLable}>
              <Input
                placeholder="Enter AR Number"
                value={
                  allBatches?.sampling_history?.length
                    ? allBatches?.sampling_history[0]?.ar_number
                    : 'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Retest Date"
              name="retest_date"
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select Retest Date"
                style={{ width: '100%' }}
                disabled={user?.role == 'user' && user?.level !== 3 ? true : ''}
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Approved On"
              name="approved_on"
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select Approved Date"
                style={{ width: '100%' }}
                disabled={user?.role == 'user' && user?.level !== 3 ? true : ''}
              />
            </FormItem>
          </Col>*/}

          {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Remarks"
              name="remarks1"
              className={styles.customLable}
            >
              <Input
                placeholder="Enter Remarks"
                disabled={user?.role == 'user' && user?.level !== 3 ? true : ''}
              />
            </FormItem>
          </Col> */}
        </Row>
      </Card>
      {/* <br /> */}
      {/* <Card className={`${styles.cardStyles_approver}`}> */}
      {/* <Row gutter={24}>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Transaction Date"
              name="transaction_date"
              className={styles.customLable}
              rules={[
                {
                  required: true,
                  message: 'Select Transaction Date'
                }
              ]}
            >
              <DatePicker
                placeholder="Select Transaction Date"
                style={{ width: '100%' }}
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Product Batch Number"
              className={styles.customLable}
              name="product_batch_number"
              rules={[
                {
                  required: false,
                  message: 'Enter Product Batch NUmber'
                }
              ]}
            >
              <Input placeholder="Enter Batch Number" />
            </FormItem>
          </Col> */}
      {/* <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Quantity Issued"
              name="quantity_issued"
              className={styles.customLable}
              rules={[
                {
                  required: false,
                  message: 'Enter Issued Quantity'
                }
              ]}
            >
              <InputNumber
                min={0}
                placeholder="Enter Issued Quantity"
                style={{ width: '100%' }}
                disabled={user?.role == 'user' && user?.level !== 3 ? true : ''}
                // onChange={
                // (e) =>
                // e > 0
                //   ? setClosingBal(
                //       allBatches?.stock_card_issuance?.length
                //         ? parseFloat(closingBal) - parseFloat(e)
                //         : parseFloat(allBatches?.received_quantity) -
                //             parseFloat(e)
                //     )
                //   : setClosingBal(
                //       allBatches?.stock_card_issuance[
                //         allBatches?.stock_card_issuance?.length - 1
                //       ]?.closing_balance
                //     )
                // }
              />
            </FormItem>
          </Col> */}
      {/* <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Quantity Returned"
              name="quantity_returned"
              className={styles.customLable}
            >
              <InputNumber
                min={0}
                placeholder="Enter Returned Quantity"
                style={{ width: '100%' }}
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Returned Qty Inhouse Batch #"
              className={styles.customLable}
              name="returned_quantity_batch"
            >
              <Input placeholder="Returned Quantity Batch #" />
            </FormItem>
          </Col> */}
      {/* <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem label="MRN #" className={styles.customLable}>
              <Input
                placeholder="MRN Number"
                // value={allBatches?.mrn_number}
                // disabled
              />
            </FormItem>
          </Col> */}
      {/* <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Transactin Done By"
              // name="transaction_done"
              className={styles.customLable}
            >
              <Input
                placeholder="Transaction Done By"
                value={user?.employee}
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={24} span={12} md={24} sm={24}>
            <FormItem
              label="Remarks"
              name="remarks2"
              className={styles.customLable}
            >
              <Input placeholder="Remarks" />
            </FormItem>
          </Col>
        </Row> */}
      {/* <FormItem className="text-center">
          <CustomButton  type="primary" htmlType="submit">
            Update
          </CustomButton >
        </FormItem> */}
      {/* </Card> */}
      <br />
      {/*user?.role === 'qa_initiator' ||
        (user?.role === 'qa_super_user' */}

      <Card className={`${styles.cardStyles_approver1}`}>
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            pagination={false}
            // scroll={{ x: 'auto' }}
            dataSource={allBatches?.stock_card_issuance}
          />
        </div>
        <br />
      </Card>
      {console.log('All Batches-whole: ', allBatches)}
      {allBatches?.removed_stock?.length ? (
        <>
          {(user?.role == 'qc_initiator' || user?.role === 'qa_super_user') &&
          user?.level == 3 ? (
            <Card
              className={`${styles.cardStyles_approver}`}
              style={{ marginTop: '20px' }}
            >
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={24}>
                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="Quantity"
                      //name="removed_qty"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Quantity'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      {console.log('AllBatches dups: ', allBatches)}
                      {console.log(
                        'removedqty: ',
                        allBatches?.[0]?.removed_qty
                      )}
                      {(() => {
                        const batch = Array.isArray(allBatches?.removed_stock)
                          ? allBatches.removed_stock.find(
                              (stock) => stock.removed_qty
                            )
                          : null;

                        return (
                          <Input value={batch?.removed_qty || 'NA'} disabled />
                        );
                      })()}
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8} md={24} sm={24}>
                    <FormItem
                      label="WH Remarks"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Remarks'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      {(() => {
                        const batch = Array.isArray(allBatches?.removed_stock)
                          ? allBatches.removed_stock.find(
                              (stock) => stock.removed_remarks
                            )
                          : null;

                        return (
                          <Input
                            value={batch?.removed_remarks || 'NA'}
                            disabled
                          />
                        );
                      })()}
                    </FormItem>
                  </Col>

                  <Col xs={24} xl={8} span={8} md={24} sm={24}>
                    <FormItem
                      label="QA Remarks"
                      name="qa_remarks"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Remarks'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                      //value={removed_remarks || 'NA'}
                      //disabled
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Form>

              {user?.role == 'qc_initiator' && user?.level == 3 ? (
                <FormItem className="text-center">
                  <Space>
                    <CustomButton
                      type="primary"
                      onClick={() => handleReject()}
                      className={styles.inwardButton_reject}
                    >
                      Reject
                    </CustomButton>
                    <CustomButton
                      type="primary"
                      onClick={() => handleApproveOpenModal('Approved')}
                      className={styles.inwardButton_approve}
                    >
                      Approve
                    </CustomButton>

                    {/* <CustomButton

                  type="primary"

                  onClick={showApproveModal}
                >
                  Approve
                </CustomButton> */}

                    {/* Approval Confirmation Modal */}
                    {isApproveModalVisible && (
                      <CustomModal
                        title="Confirm Approval"
                        visible={isApproveModalVisible}
                        width="30%"
                        footer={null}
                        className={styles.customModal}
                        closable={false}
                      >
                        <div
                          style={{ textAlign: 'center', marginBottom: '20px' }}
                        >
                          Are you sure you want to approve?
                        </div>

                        <FormItem className="text-center">
                          <Space>
                            {/* Cancel Button */}
                            <CustomButton
                              className={styles.inwardButton}
                              //onClick={handleReject}
                              type="primary"
                              size="small"
                              ghost
                              onClick={handleApproveCancel}
                            >
                              Cancel
                            </CustomButton>
                            {/* Approve Button */}
                            <CustomButton
                              className={styles.inwardButton}
                              // onClick={handleApprove}
                              htmlType="submit"
                              // type="primary"
                              size="small"
                              onClick={() => handleSubmit()}
                            >
                              Approve
                            </CustomButton>
                          </Space>
                        </FormItem>
                      </CustomModal>
                    )}
                  </Space>
                </FormItem>
              ) : (
                ''
              )}

              {console.log(
                'Status123: ',
                allBatches?.removed_stock?.[0]?.status
              )}
            </Card>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {user?.role == 'user' && user?.level == 3 ? (
          <>
            {allBatches?.removed_stock?.[0]?.status === 'Rejected' ? (
              <div style={{ position: 'relative' }}>
                <EditOutlined
                  style={{
                    position: 'absolute',
                    top: '-10px',
                    right: '10px',
                    fontSize: '20px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIsModalVisible(true)}
                />

                <Row gutter={24}>
                  <Col xs={24} xl={4} span={12} md={24} sm={24}>
                    <FormItem
                      label="Quantity"
                      name="removed_qty"
                      className={styles.customLable}
                    >
                      {console.log('QA qty: ', allBatches?.removed_qty)}
                      {(() => {
                        const batch = Array.isArray(allBatches?.removed_stock)
                          ? allBatches.removed_stock.find(
                              (stock) => stock.removed_qty
                            )
                          : null;

                        return (
                          <Input value={batch?.removed_qty || 'NA'} disabled />
                        );
                      })()}
                    </FormItem>
                  </Col>

                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="WH Remarks"
                      //name="removed_remarks"
                      className={styles.customLable}
                    >
                      {(() => {
                        const batch = Array.isArray(allBatches?.removed_stock)
                          ? allBatches.removed_stock.find(
                              (stock) => stock.removed_remarks
                            )
                          : null;

                        return (
                          <Input
                            value={batch?.removed_remarks || 'NA'}
                            disabled
                          />
                        );
                      })()}
                    </FormItem>
                  </Col>

                  <Col xs={24} xl={8} span={12} md={24} sm={24}>
                    <FormItem
                      label="QA Remarks"
                      name="qa_remarks"
                      className={styles.customLable}
                    >
                      {console.log}
                      {(() => {
                        const batch = Array.isArray(allBatches?.removed_stock)
                          ? allBatches.removed_stock.find(
                              (stock) => stock.qa_remarks
                            )
                          : null;

                        return (
                          <Input value={batch?.qa_remarks || 'NA'} disabled />
                        );
                      })()}
                    </FormItem>
                  </Col>
                </Row>
              </div>
            ) : null}
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* <CustomButton
                className={styles.inwardButton}
                style={{ borderRadius: '5px', marginRight: '10px' }}
                onClick={() => history.back('/stock-card')}
              >
                Back
              </CustomButton> */}

              <CustomButton
                className={styles.inwardButton}
                htmlType="button"
                type="primary"
                style={{ borderRadius: '5px' }}
                onClick={() => setIsModalVisible(true)} // Show modal on click
              >
                Stock adjustment
              </CustomButton>
              {console.log('Status: ', status)}
            </div>
          </>
        ) : (
          ''
        )}
      </Form>

      {isModalVisible ? (
        <CustomModal
          title="Stock adjustment"
          visible={isModalVisible}
          width="50%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem
                  label="Quantity"
                  name="removed_qty"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Quantity'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter Quantity" />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="Remarks"
                  name="removed_remarks"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Remarks'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea placeholder="Enter Remarks" />
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  size="small"
                >
                  Initiate Verification
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
        </CustomModal>
      ) : (
        ''
      )}
      <br />
      <FormItem className="text-center">
        <Space>
          {allBatches?.status === 'OnHold' ? (
            <CustomButton
              type="primary"
              onClick={() => handleOpenModal('Release')}
            >
              Release
            </CustomButton>
          ) : (
            <CustomButton
              type="primary"
              onClick={() => handleOpenModal('OnHold')}
            >
              On Hold
            </CustomButton>
          )}
          <CustomButton
            type="primary"
            ghost
            onClick={() => handleOpenModal('Rejected')}
          >
            Reject
          </CustomButton>
          <CustomButton onClick={() => history.back()}>
            Back To StockCard
          </CustomButton>
        </Space>
      </FormItem>
      {isModal ? (
        <CustomModal
          title={modalTitles?.[0][status]}
          visible={isModal}
          width="30%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <Form form={modalform} layout="vertical" onFinish={onFinishModal}>
            {status === 'Release' ? (
              <FormItem
                label="Please select the status of the batch from the options mentioned below"
                name="status"
                rules={[
                  {
                    required: false,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group>
                  <Radio value="Approved">Approved</Radio>
                  <Radio value="Quarantine">Quarantine</Radio>
                </Radio.Group>
              </FormItem>
            ) : (
              ''
            )}
            <FormItem
              label="Comments"
              name="comments"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Comments" />
            </FormItem>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  size="small"
                >
                  Yes
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => setIsModal(false)}
                  type="primary"
                  size="small"
                >
                  No
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
        </CustomModal>
      ) : (
        ''
      )}
    </Form>
  );
};
export default StockCardIssuancePage;
