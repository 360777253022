import React, { useState, useEffect } from 'react';
import { Form, Card, Select, Row, Col, Input, Tag, Spin, Tooltip } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterialslist, downloadPdfFile } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import { CloudDownloadOutlined } from '@ant-design/icons';
import CustomButton from '@/common/CustomButton';
// import { history } from '@/app/history';

const StockCardPage = () => {
  const [form] = Form.useForm();

  const { allMaterials, approvedqty, unapprovedqty } = useSelector(
    (state) => state.stocks
  );

  // const [isModalVisible, setIsModalVisible] = useState(false);

  const { user } = getLoggedInUser();

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [code, setCode] = useState('');
  const [batches, setBatches] = useState([]);
  const [materialId, setMaterialId] = useState('');
  console.log(batches);
  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  const onChange = (value, type) => {
    setMaterialId(value);
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );

    let payload = { search: material[0].code };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        setBatches(response?.payload?.data[0]?.batch_details);
        // dispatch(getAllMaterialslist());
      }
    });
    form.setFieldsValue({ supplier_id: null });
    type == 'id'
      ? form.setFieldsValue({ material_name: value })
      : form.setFieldsValue({ material_id: value });
    setCode(material[0]);
  };
  console.log(unapprovedqty, 'unapprovedqty');
  const onSearch = (value) => {
    setSearch(value);
  };

  const onChangeBatch = (value) => {
    const batches_details =
      value == 'all'
        ? allMaterials[0]?.batch_details
        : batches.filter((batches) => batches.id === value);
    setBatches(batches_details);
  };
  const onSearchBatch = (value) => {
    const batches_details = batches.inhouse_batch_number
      .toLowerCase()
      .includes(value.toLowerCase());
    setBatches(batches_details);
  };

  const downloadPdf = () => {
    let payload = { material_id: materialId };
    dispatch(downloadPdfFile(payload)).then((response) => {
      if (response?.payload?.success) {
        window.open(`${response?.payload?.data}`);
      }
    });
  };

  const columns = [
    {
      title: 'Inhouse Batch #',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.status == 'Approved' && user?.role == 'user' ? (
          // &&
          // user?.level == 3
          <a href={`stock-card-issuance/${record.id}`}>{text}</a>
        ) : (
          // text
          // text;
          <a href={`stock-card-issuance/${record.id}`}>{text}</a>
        );
      }
    },
    {
      title: 'MRN #',
      dataIndex: 'mrn_number',
      key: 'mrn_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.mrn_number || 'NA';
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        console.log('text: ', text);

        return text == 'Approved' ? (
          <Tag color="#4CAF50">{text?.toUpperCase()}</Tag>
        ) : text == 'Quarantine' ? (
          <Tag color="#FF9800">{text?.toUpperCase()}</Tag>
        ) : text == 'Expired' ? (
          <Tag color="#939089d9">{text?.toUpperCase()}</Tag>
        ) : text == 'Rejected' ? (
          <Tag color="#fa2a3e">{text?.toUpperCase()}</Tag>
        ) : text === 'Under Sampling' ? (
          <Tag color="#17a2b8">{text?.toUpperCase()}</Tag>
        ) : text === 'Under Test' ? (
          <Tag color="#343a40">{text?.toUpperCase()}</Tag>
        ) : text === 'Modification Pending' ? (
          <Tag color="#722ed1">{text?.toUpperCase()}</Tag>
        ) : text === 'OnHold' ? (
          <Tag color="#781720">{text?.toUpperCase()}</Tag>
        ) : (
          <Tag color="#4CAF50">{text?.toUpperCase()}</Tag>
        );
      }
    },

    {
      title: 'Closing Qty',
      dataIndex: 'closing_quantity',
      key: 'closing_quantity',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        // var issued_qty = 0;
        // record?.stock_card_issuances?.map((item) => {
        //   issued_qty += parseFloat(item?.quantity_issued || 0);
        // });
        console.log(
          record?.stock_card_issuances?.[
            record?.stock_card_issuances?.length - 1
          ]?.closing_balance,
          'issued_qty'
        );
        return (
          `${
            record?.stock_card_issuances?.[
              record?.stock_card_issuances?.length - 1
            ]?.closing_balance
          }  ${code?.uom}` || 'NA'

          // Quantity Received (-) Sampling Quantity (+ or -) Stock Reconciliation Qty (-) Wastage Material Qty (+) Returned Qty
          // // `${
          // //   parseFloat(record?.received_quantity || 0) -
          // //   parseFloat(record?.sampling?.[0]?.total_value || 0) +
          // //   parseFloat(
          // //     record?.stock_reconsiliation?.[0]?.total_reconcile || 0
          // //   ) -
          // //   parseFloat(record?.wastage_quantity?.[0]?.total_wastage || 0) +
          // //   parseFloat(
          // //     record?.stock_card_issuances?.[0]?.quantity_returned || 0
          // //   )
          // } ${code?.uom}`
        );
      }
    },
    {
      title: 'Mfg Batch/Lot #',
      dataIndex: 'mfg_batch_lot_no',
      key: 'mfg_batch_lot_no',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Mfg Date',
      dataIndex: 'mfg_date',
      key: 'mfg_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.mfg_date
          ? moment(record?.mfg_date).format('DD MMM YYYY')
          : 'NA';
      }
    },
    {
      title: 'Mfg Expiry Date',
      dataIndex: 'mfg_expiry_date',
      key: 'mfg_expiry_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.mfg_expiry_date
          ? moment(record?.mfg_expiry_date).format('DD MMM YYYY')
          : 'NA';
      }
    },
    {
      title: 'Mfg Re-test Date',
      dataIndex: 'mfg_retest_date',
      key: 'mfg_retest_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.mfg_retest_date
          ? moment(record?.mfg_retest_date).format('DD MMM YYYY')
          : 'NA';
      }
    },
    {
      title: 'Inhouse Expiry Date',
      dataIndex: 'in_house_expiry_date',
      key: 'in_house_expiry_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.in_house_expiry_date
          ? moment(record?.in_house_expiry_date).format('DD MMM YYYY')
          : 'NA';
      }
    },
    {
      title: 'Inhouse Retest Date',
      dataIndex: 'inhouse_retest_date',
      key: 'inhouse_retest_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.inhouse_retest_date
          ? moment(record?.inhouse_retest_date).format('DD MMM YYYY')
          : 'NA';
      }
    },
    {
      title: 'Received Qty',
      dataIndex: 'quantity_received',
      key: 'quantity_received',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          `${parseFloat(record?.received_quantity || 0).toFixed(3)}  ${
            code?.uom
          }` || 'NA'
        );
      }
    },
    {
      title: 'Damaged Qty',
      dataIndex: 'damaged_qty',
      key: 'damaged_qty',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          `${parseFloat(
            record?.damage_quantity?.[0]?.total_damage || 0
          ).toFixed(3)} ` || 0
        );
      }
    },
    {
      title: 'Sampling Qty',
      dataIndex: 'sampling_quantity',
      key: 'sampling_quantity',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          `${parseFloat(record?.sampling?.[0]?.total_value || 0).toFixed(3)}  ${
            code?.uom
          }` || 'NA'
        );
      }
    },

    {
      title: 'Approved Qty',
      dataIndex: 'approved_quantity',
      key: 'approved_quantity',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          // `${
          //   record?.stock_card_issuances?.length
          //     ? parseFloat(record?.stock_card_issuances?.[0]?.closing_balance)
          //     : parseFloat(record?.received_quantity)
          // }  ${code?.uom}` || 'NA'

          // Quantity Received (-) Sampling Quantity (+ or -) Stock Reconciliation Qty (-) Wastage Material Qty (+) Returned Qty
          record?.status === 'Quarantine'
            ? 0
            : `${
                (
                  parseFloat(record?.received_quantity || 0) -
                  parseFloat(record?.sampling?.[0]?.total_value || 0)
                ).toFixed(3)
                //  +
                // parseFloat(
                //   record?.stock_reconsiliation?.[0]?.total_reconcile || 0
                // ) -
                // parseFloat(record?.wastage_quantity?.[0]?.total_wastage || 0) +
                // parseFloat(
                //   record?.stock_card_issuances?.[0]?.quantity_returned || 0
                // )
              } ${code?.uom}`
        );
      }
    },
    {
      title: 'Issued Qty',
      dataIndex: 'issued_quantity',
      key: 'issued_quantity',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        var issued_qty = 0;
        record?.stock_card_issuances?.map((item) => {
          issued_qty += parseFloat(item?.quantity_issued || 0);
        });

        return `${parseFloat(issued_qty || 0).toFixed(3)} ${code?.uom}`;
      }
    },
    {
      title: 'Stock Reconciliation Qty',
      dataIndex: 'stock_reconciliation_qty',
      key: 'stock_reconciliation_qty',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          `${parseFloat(
            record?.stock_reconsiliation?.[0]?.total_reconcile || 0
          ).toFixed(3)}  ${code?.uom}` || 'NA'
        );
      }
    },
    {
      title: 'Wastage Material Qty',
      dataIndex: 'wastage_material_qty',
      key: 'wastage_material_qty',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          `${parseFloat(
            record?.wastage_quantity?.[0]?.total_wastage || 0
          ).toFixed(3)}  ${code?.uom}` || 'NA'
        );
      }
    },
    {
      title: 'Returned Qty',
      dataIndex: 'returned_qty',
      key: 'returned_qty',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          `${parseFloat(record?.stock_returned_qty || 0).toFixed(3)}  ${
            code?.uom
          }` || 'NA'
        );
      }
    },

    {
      title: 'AR #',
      dataIndex: 'ar_number',
      key: 'ar_number',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.sampling_histories?.length
          ? record?.sampling_histories[0]?.ar_number
          : 'NA' || 'NA';
      }
    },
    {
      title: 'Date of Receipt',
      dataIndex: 'received_date',
      key: 'received_date',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.sampling_histories?.length
          ? record?.sampling_histories[0]?.received_date
            ? moment(record?.sampling_histories[0]?.received_date).format(
                'DD MMM YYYY'
              )
            : 'NA'
          : 'NA';
      }
    },
    {
      title: 'Received by WH',
      dataIndex: 'received_by_wh',
      key: 'received_by_wh',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.received_by_wh || 'NA';
      }
    },
    {
      title: 'Checked by WH',
      dataIndex: 'checked_by_wh',
      key: 'checked_by_wh',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.checked_by_wh || 'NA';
      }
    },
    {
      title: 'Sampling Done by QC',
      dataIndex: 'sampling_done_by_qc',
      key: 'sampling_done_by_qc',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.sampling_done_by_qc || 'NA';
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.remarks || 'NA';
      }
    }
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      //   onFinish={onFinish}
    >
      <Card
        title={<span>MATERIAL STOCK CARD RECEIPT DETAILS</span>}
        className={`${styles.cardStyles_approver}`}
        extra={
          <Tooltip
            placement="top"
            title={'Material Stock Card Receipt Details'}
          >
            <CustomButton
              onClick={() => downloadPdf()}
              className={styles.qrcode}
              disabled={materialId == '' ? true : false}
            >
              <CloudDownloadOutlined />
            </CustomButton>
          </Tooltip>
        }
      >
        <Row gutter={24}>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Material Code"
              name="material_id"
              rules={[
                {
                  required: true,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                showSearch
                placeholder="Select a material code"
                optionFilterProp="children"
                onChange={(e) => onChange(e, 'id')}
                onSearch={onSearch}
              >
                {allMaterials ? (
                  allMaterials?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.code}
                      </option>
                    );
                  })
                ) : (
                  <Spin />
                )}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Material Name"
              name="material_name"
              rules={[
                {
                  required: false,
                  message: 'Select Material Name'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                showSearch
                placeholder="Select a material name"
                optionFilterProp="children"
                onChange={(e) => onChange(e, 'name')}
                onSearch={onSearch}
              >
                {allMaterials?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
              {/* <Input value={`${code?.name || 'NA'}`} disabled /> */}
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="UOM"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input value={`${code?.uom || 'NA'}`} disabled />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="In House Batch #"
              rules={[
                {
                  required: true,
                  message: 'Select In House Batch Number'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                showSearch
                placeholder="Select In House Batch Number"
                optionFilterProp="children"
                onChange={onChangeBatch}
                onSearch={onSearchBatch}
              >
                <option value="all">All</option>
                {batches?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.inhouse_batch_number}
                    </option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={8} md={24} sm={24}>
            <FormItem
              label="Manufacturer Name"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={
                  code !== ''
                    ? `${code?.suppliers[0]?.manufacturer_name || 'NA'}`
                    : ''
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Suppliers Name"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={code !== '' ? `${code?.suppliers[0]?.name || 'NA'}` : ''}
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Total Approved Qty"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input value={approvedqty || 'NA'} disabled />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={12} md={24} sm={24}>
            <FormItem
              label="Total Unapproved Qty"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input value={unapprovedqty || 'NA'} disabled />
            </FormItem>
          </Col>
        </Row>
      </Card>
      <br />

      <Card className={`${styles.subpanel}`}>
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            pagination={false}
            scroll={{ x: 'auto' }}
            dataSource={batches}
          />
        </div>
      </Card>
    </Form>
  );
};
export default StockCardPage;
