import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { materials, inventory } from './api';

const initialState = {
  allMaterials: [],
  approvedqty: '',
  unapprovedqty: ''
};

const actions = {
  GET_ALL_MATERIALS: 'stocks/GET_ALL_MATERIALS',
  DOWNLOAD_PDF: 'stocks/DOWNLOAD_PDF'
};

export const getAllMaterialslist = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await materials('all', payload);

    return response;
  }
);

export const downloadPdfFile = createAsyncThunk(
  actions.DOWNLOAD_PDF,
  async (payload) => {
    const response = await inventory('download', payload);

    return response;
  }
);

export const stocksSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaterialslist.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllMaterialslist.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const {
          success,
          data,
          totalClosingQtyForApproved,
          totalClosingQtyForUnApproved,
          pagination
        } = action.payload;
        if (success) {
          state.allMaterials = data;
          state.approvedqty = totalClosingQtyForApproved;
          state.unapprovedqty = totalClosingQtyForUnApproved;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllMaterialslist.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(downloadPdfFile.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(downloadPdfFile.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(downloadPdfFile.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default stocksSlice.reducer;
