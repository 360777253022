import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  Select,
  Input,
  DatePicker,
  Space,
  message,
  Image
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import {
  createMaterial,
  getAllMaterialsTypes,
  getAllUoms,
  createMaterialType
} from '../../redux/slice';
import { useDispatch } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import CustomModal from '@/common/CustomModal';
import { PlusCircleFilled } from '@ant-design/icons';

const { TextArea } = Input;

const CreateMaterial = ({ loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const { allqcMaterials } = useSelector((state) => state.material);
  // console.log(allqcMaterials);

  // const [code, setCode] = useState('');
  // const [search, setSearch] = useState('');
  const [materials, setMaterials] = useState([]);
  const [truckShow, setTruckShow] = useState(false);
  const [iSModal, setISModal] = useState(false);
  const [matType, setMatType] = useState('');
  const [uoms, setUoms] = useState([]);
  const [uomType, setUomType] = useState([
    {
      material_type: '',
      short_name: '',
      uom_type: '',
      model_type: ''
    }
  ]);
  // const [isSubmit, setIsSubmit] = useState(false);

  // console.log(uoms);

  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetAllUoms();
  }, []);

  const handleGetAllMaterialsList = () => {
    let payload = {};
    dispatch(getAllMaterialsTypes(payload)).then((response) => {
      if (response?.payload?.success) {
        setMaterials(response?.payload?.data);
      }
    });
  };

  const handleGetAllUoms = () => {
    let payload = {};
    dispatch(getAllUoms(payload)).then((response) => {
      if (response?.payload?.success) {
        setUoms(response?.payload?.data);
      }
    });
  };

  const handleSubmit = () => {
    console.log(matType);
    const data = uomType[0];
    console.log(data, 'daya');
    data['type'] = matType;

    dispatch(createMaterialType(data, matType)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        setISModal(false);
        message.success(response?.payload?.message);
        handleGetAllMaterialsList();
        handleGetAllUoms();
      }
    });
  };
  const onFinish = (values) => {
    console.log(values);
    setTruckShow(true);
    // if (isSubmit) {
    //   const data = {
    //     material_type: values?.material_name,
    //     uom_type: values?.uom_type
    //   };
    //   dispatch(createMaterial(data)).then((response) => {
    //     if (response?.payload?.success) {
    //       setTruckShow(false);
    //       message.success(response?.payload?.message);
    //       handleGetAllMaterialsList();
    //       handleGetAllUoms();
    //     }
    //   });
    // } else {
    const payload = {
      name: values?.material_name,
      code: values?.material_code,
      uom: values?.uom,
      item: values?.type,
      temp: values?.temparature,
      weighingrules: values?.weighingrules,
      // values?.type == 1
      //   ? 1
      //   : values?.type == 2
      //   ? 2
      //   : values?.type == 3 && values?.uom == 3
      //   ? 3
      //   : (values?.type == 3 && values?.uom == 2) ||
      //     (values?.type == 3 && values?.uom == 2)
      //   ? 2
      //   : values?.uom == 3
      //   ? 3
      //   : 4,
      vendor_status: values?.status,
      supplier_name: values?.suppliers_name,
      address1: values?.supplier_address,
      manufacturer_name: values?.manufacturer_name,
      manufacturer_address: values?.manufacturer_address,
      requalification_due: values?.requalification_due,
      tolerance_months: values?.tollerence
    };
    dispatch(createMaterial(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/material-list');
      }
    });
    // }
  };

  const handleModal = (type) => {
    setISModal(true);
    setMatType(type);
    let list = [...uomType];
    list[0]['model_type'] = type;
    setUomType(list);
  };
  console.log(matType);
  const handleOnChange = (e, type) => {
    console.log(type, e.target.value);
    let list = [...uomType];
    type === 'material_type'
      ? (list[0]['material_type'] = e.target.value)
      : type === 'short_name'
      ? (list[0]['short_name'] = e.target.value)
      : type === 'uom_type'
      ? (list[0]['uom_type'] = e.target.value)
      : (list[0]['model_type'] = e.target.value);
    setUomType(list);
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card title="ADD NEW MATERIAL" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={4} span={8} md={24} sm={24}>
              <FormItem
                label="Material Code"
                name="material_code"
                rules={[
                  {
                    required: true,
                    message: 'Select material code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Material Code" />
              </FormItem>
            </Col>
            <Col xs={24} xl={4} span={8} md={24} sm={24}>
              <FormItem
                label="Name of the Material"
                name="material_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter name of the material'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter name of the material" />
              </FormItem>
            </Col>
            <Col xs={24} xl={4} span={4} md={24} sm={24}>
              <FormItem
                label="Type of Material"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Enter name of the material type'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select Material Type">
                  {materials?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.material_type}
                      </option>
                    );
                  })}
                  {/* <option>
                    <CustomButton onClick={() => setISModal(true)}>
                      Add Other
                    </CustomButton>
                  </option> */}
                </Select>

                {/* <Select placeholder="Select Material Type">
                  <option value="RM">Raw Material</option>
                  <option value="PM">Packing Material</option>
                  <option value="API">API</option>
                  <option value="PC">Process Chemical</option>
                </Select> */}
              </FormItem>
              {/* <span onClick={() => setISModal(true)}>Add Other</span> */}
            </Col>
            <Col xs={24} xl={2} span={4} md={24} sm={24}>
              <FormItem
                label="Add Type"
                rules={[
                  {
                    required: false,
                    message: 'Enter UOM'
                  }
                ]}
                className={styles.customLable}
              >
                <CustomButton onClick={() => handleModal('type')}>
                  <PlusCircleFilled />
                </CustomButton>
              </FormItem>
            </Col>
            <Col xs={24} xl={4} span={4} md={24} sm={24}>
              <FormItem
                label="Weighing Rules"
                name="weighingrules"
                rules={[
                  {
                    required: true,
                    message: 'Enter name of the material type'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select Material Type">
                  <option value={3}>No Weighing</option>
                  <option value={2}>Root n+1</option>
                  <option value={1}>100% Weighing</option>
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={4} span={4} md={24} sm={24}>
              <FormItem
                label="UOM"
                name="uom"
                rules={[
                  {
                    required: true,
                    message: 'Enter UOM'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select UOM">
                  {uoms?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.uom_type}
                      </option>
                    );
                  })}
                  {/* <option value="KG">KG</option>
                  <option value="MT">MT</option>
                  <option value="No's">{`No's`}</option>
                  <option value="LT">LT</option> */}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={2} span={4} md={24} sm={24}>
              <FormItem
                label="Add UOM"
                rules={[
                  {
                    required: false,
                    message: 'Enter UOM'
                  }
                ]}
                className={styles.customLable}
              >
                <CustomButton onClick={() => handleModal('uom')}>
                  <PlusCircleFilled />
                </CustomButton>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Manufacturer Name"
                name="manufacturer_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Manufacturer Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Manufacturer Name" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Supplier Name"
                name="suppliers_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Suppliers Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Suppliers Name" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Manufacturer Address"
                name="manufacturer_address"
                rules={[
                  {
                    required: true,
                    message: 'Enter Manufacturer Address'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea rows={4} placeholder="Enter Manufacturer Address" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Supplier Address"
                name="supplier_address"
                rules={[
                  {
                    required: true,
                    message: 'Enter Suppliers Address'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea rows={4} placeholder="Enter Suppliers Address" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Requalification Due"
                name="requalification_due"
                rules={[
                  {
                    required: true,
                    message: 'Enter Requalification Due'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Select Requalification Due Date"
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Vendor Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Enter Vendor Status'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select Status">
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="partially_approved">Partially Approved</option>
                  <option value="provisional_approved">
                    Provisional Approved
                  </option>
                  <option value="blocked">Blocked</option>
                  <option value="under_evaluation">Under Evaluation</option>
                  <option value="disqualified">Disqualified</option>
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Tolerance Months"
                name="tollerence"
                rules={[
                  {
                    required: true,
                    message: 'Enter Tolerance Months'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select Tolerance Months">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Storage Temperature Condition"
                name="temparature"
                rules={[
                  {
                    required: true,
                    message: 'Enter Storage Temperature Condition'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter MStorage Temperature Condition" />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton htmlType="button" onClick={() => history.back()}>
              Cancel
            </CustomButton>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton type="primary" htmlType="submit" loading={loading}>
                Add New Material
              </CustomButton>
            )}
          </Space>
        </FormItem>

        {iSModal ? (
          <CustomModal
            title="Add material Type"
            visible={iSModal}
            width="50%"
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <br />
            <Row gutter={24}>
              {matType == 'type' ? (
                <>
                  <Col xs={24} xl={12} span={8} md={24} sm={24}>
                    <FormItem
                      label="Material type"
                      name="material_type"
                      rules={[
                        {
                          required: true,
                          message: 'Select material type'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Material Type"
                        onChange={(e) => handleOnChange(e, 'material_type')}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={8} md={24} sm={24}>
                    <FormItem
                      label="Material short name"
                      name="short_name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Short name'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Short Name"
                        onChange={(e) => handleOnChange(e, 'short_name')}
                      />
                    </FormItem>
                  </Col>
                </>
              ) : (
                <Col xs={24} xl={12} span={8} md={24} sm={24}>
                  <FormItem
                    label="UOM"
                    name="uom_type"
                    rules={[
                      {
                        required: true,
                        message: 'Select material type'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Material UOM"
                      onChange={(e) => handleOnChange(e, 'uom_type')}
                    />
                  </FormItem>
                </Col>
              )}
            </Row>
            <FormItem className="text-center">
              <Space size={6}>
                <CustomButton
                  className={styles.inwardButton}
                  type="primary"
                  size="small"
                  onClick={() => handleSubmit()}
                  // htmlType="submit"
                >
                  Add
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => setISModal(false)}
                  type="primary"
                  size="small"
                >
                  Close
                </CustomButton>
              </Space>
            </FormItem>
          </CustomModal>
        ) : (
          ''
        )}
      </Form>
    </Layout>
  );
};
export default CreateMaterial;
