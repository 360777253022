import React, { useState, useEffect } from 'react';
import {
  Form,
  Card,
  Select,
  Row,
  Col,
  Input,
  Spin,
  message,
  Image,
  DatePicker,
  Space
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllContainers,
  createSampling,
  getPastSamplingDetails,
  getsamples
  // updateSampling
} from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import {
  QrcodeOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { QrReader } from 'react-qr-reader';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import { getLoggedInUser } from 'utilities/helpers';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import VerificationModal from '@/features/material/components/VerificationModal';
// import dayjs from 'dayjs';

const { TextArea } = Input;
const SamplingMaterialsPage = () => {
  const dateFormat = 'DD-MMM-YYYY';

  const [form] = Form.useForm();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  const { allMaterials } = useSelector((state) => state.stocks);
  const { user } = getLoggedInUser();

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [containers, setContainers] = useState([]);
  const [openCam, setOpenCam] = useState(false);
  const [containerDetails, setContainerDetails] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [containerChange, setContainerChange] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [truckShow, setTruckShow] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [sampleData, setSampleData] = useState({});
  const [samplingData, setSamplingData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  console.log(setContainerDetails, pastData);
  console.log(containerDetails);
  useEffect(() => {
    handleGetSamplesById();
  }, []);

  const handleGetSamplesById = () => {
    const payload = { id: searchParams.get('id') };
    dispatch(getsamples(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setContainerDetails([
          {
            batch_detail: response?.payload?.data?.container?.batch_detail,
            createdAt: response?.payload?.data?.createdAt,
            received_date: response?.payload?.data?.received_date
          }
        ]);
        form.setFieldsValue({
          warehouse_containers: response?.payload?.data?.container?.number,
          quantity: response?.payload?.data?.quantity
            ? parseFloat(
                response?.payload?.data?.uom == `No.'s`
                  ? response?.payload?.data?.quantity
                  : response?.payload?.data?.quantity * 1000
              ).toFixed(3)
            : 'NA',
          retest_date: response?.payload?.data?.retest_date
            ? moment(response?.payload?.data?.retest_date)
            : '',
          ar_number: response?.payload?.data?.batch_detail?.ar_number || 'NA',
          uom: response?.payload?.data?.uom,
          sampling_date: moment(response?.payload?.data?.sampling_date),
          received_date: moment(response?.payload?.data?.received_date)
        });
      }
    });
  };
  const onChange = (value) => {
    console.log(value);
    setContainerChange(true);
    const details = containers.filter(
      (containers) => containers.number === value
    );
    setContainerDetails(details);
    const payload = { container_id: value };
    dispatch(getPastSamplingDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setPastData(response?.payload?.data);
      }
    });
  };
  console.log(allMaterials);
  console.log(containers);

  const onSearch = (value) => {
    setSearch(value);
    let payload = { search: search };
    search.length > 5
      ? dispatch(getAllContainers(payload)).then((response) => {
          if (response?.payload?.success) {
            setContainers(response?.payload?.data);
          }
        })
      : '';
  };
  const handleQrScan = async (scanData) => {
    if (scanData && scanData !== '') {
      const details = containers.filter(
        (containers) =>
          containers.number ===
          scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
      );
      setContainerDetails(details);
      form.setFieldsValue({
        warehouse_containers: scanData.text.slice(
          scanData.text.lastIndexOf('/') + 1
        )
      });
      const payload = {
        container_id: scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
      };
      dispatch(getPastSamplingDetails(payload)).then((response) => {
        if (response?.payload?.success) {
          setPastData(response?.payload?.data);
        }
      });
      setOpenCam(false);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const handleAddContainer = () => {
    console.log('AAA');
    console.log(form.getFieldValue('warehouse_containers'));
    setSamplingData([
      ...samplingData,
      {
        container_id: containerDetails[0]?.id,
        uom: form.getFieldValue('uom'),
        quantity: form.getFieldValue('quantity'),
        received_date: containerDetails[0]?.createdAt,
        sampling_date: moment().format('YYYY-MM-DD'),
        batch_details_id:
          containerDetails[0]?.batch_detail?.inhouse_batch_number,
        container_num: form.getFieldValue('warehouse_containers'),
        notes: ''
      }
    ]);
    form.resetFields();
  };

  const removeContainer = (index) => {
    console.log('remove', index);
    const newSamplingData = [...samplingData];
    newSamplingData.splice(index, 1);
    setSamplingData(newSamplingData);
  };
  console.log(samplingData);

  const onSubmit = () => {
    setIsSubmit(true);
    const payload = {
      batch_details_id: containerDetails[0]?.batch_detail?.id,
      samplingData
    };
    dispatch(createSampling(payload)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/samples-list');
        setIsSubmit(false);
      } else {
        setTruckShow(false);
        setIsSubmit(false);
      }
    });
  };
  const onFinish = (values) => {
    console.log(containerDetails);
    setTruckShow(true);
    if (containerChange && !searchParams.get('id')) {
      // const payload = {
      //   container_id: containerDetails[0]?.id,
      //   batch_details_id: containerDetails[0]?.batch_detail?.id,
      //   quantity: values?.quantity,
      //   received_date: containerDetails[0]?.createdAt,
      //   ar_number: values?.ar_number,
      //   sampling_date: moment().format('YYYY-MM-DD'),
      //   retest_date: values?.retest_date,
      //   uom: values?.uom
      // };
      const payload = {
        batch_details_id: containerDetails[0]?.batch_detail?.id,
        samplingData
      };
      dispatch(createSampling(payload)).then((response) => {
        if (response?.payload?.success) {
          setTruckShow(false);
          message.success(response?.payload?.message);
          history.push('/samples-list');
        } else {
          setTruckShow(false);
        }
      });
    } else if (searchParams.get('id')) {
      setIsModal(true);
      const payload = {
        container_id: containerDetails[0]?.id,
        batch_details_id: containerDetails[0]?.batch_detail?.id,
        quantity: values?.quantity,
        received_date: containerDetails[0]?.createdAt,
        ar_number: values?.ar_number,
        id: searchParams.get('id'),
        notes: values?.notes,
        uom: values?.uom,
        retest_date: values?.retest_date
      };
      setSampleData(payload);
      // dispatch(updateSampling(payload)).then((response) => {
      //   if (response?.payload?.success) {
      //     setTruckShow(false);
      //     message.success(response?.payload?.message);
      //     history.push('/samples-list');
      //   } else {
      //     setTruckShow(false);
      //   }
      // });
    }
  };

  // const handleQA = () => {
  //   setIsModal(true);
  // };

  const columns = [
    {
      title: 'Container Number',
      dataIndex: 'container_num',
      key: 'container_num',
      ellipsis: true,
      width: '100px'
      // render: (_, record) => {
      //   return record?.container?.number ? record?.container?.number : 'NA';
      // }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          `${parseFloat(record?.quantity).toFixed(3)}${record?.uom}` || 'NA'
        );
      }
    },
    {
      title: 'Batch Number',
      dataIndex: 'batch_details_id',
      key: 'batch_details_id',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Sampling Date',
      dataIndex: 'sampling_date',
      key: 'sampling_date',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return moment(text).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Action',
      dataIndex: 'index',
      key: 'id',
      ellipsis: true,
      width: '100px',
      // render: (_, _, index) => {
      //   return <DeleteOutlined onClick={() => removeContainer(index)} />;
      // }
      render: (text, record, index) => {
        return <DeleteOutlined onClick={() => removeContainer(index)} />;
      }
    }
  ];

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Card
        title={<span>UPDATE SAMPLING WEIGHT DETAILS</span>}
        className={`${styles.cardStyles_approver}`}
        extra={
          user?.level == 3 ? (
            <CustomButton onClick={() => setIsEdit(false)}>
              <EditOutlined />
            </CustomButton>
          ) : (
            ''
          )
        }
      >
        <Row gutter={24}>
          <Col xs={2} xl={2} span={2} md={2} sm={2}>
            <CustomButton
              onClick={() => setOpenCam(true)}
              className={styles.qrcode}
            >
              <QrcodeOutlined onClick={() => setOpenCam(true)} />
            </CustomButton>
          </Col>
          <Col xs={10} xl={6} span={6} md={10} sm={10}>
            <FormItem
              name="warehouse_containers"
              label="Warehouse Containers"
              rules={[
                {
                  required: false,
                  message: 'Enter warehouse containers'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                showSearch
                placeholder="Scan or select container"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                disabled={searchParams.get('id') ? true : false}
              >
                {containers?.map((item, index) => {
                  return containers ? (
                    <option value={item.number} key={index}>
                      {item.number}
                    </option>
                  ) : (
                    <Spin />
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="AR Number"
              // name="ar_number"
              rules={[
                {
                  required: false,
                  message: 'Enter AR Number'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={
                  searchParams.get('id') !== ''
                    ? containerDetails[0]?.batch_detail?.ar_number
                    : containerDetails[0]?.batch_detail?.ar_number || 'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Material Code"
              rules={[
                {
                  required: true,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                disabled
                value={
                  searchParams.get('id') !== ''
                    ? containerDetails[0]?.batch_detail?.material?.code
                    : containerDetails[0]?.batch_detail?.material?.code || 'NA'
                }
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Material Name"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={`${
                  searchParams.get('id')
                    ? containerDetails[0]?.batch_detail?.material?.name
                    : containerDetails[0]?.batch_detail?.material?.name || 'NA'
                }`}
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Batch Number"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={
                  searchParams.get('id') !== ''
                    ? containerDetails[0]?.batch_detail?.inhouse_batch_number
                    : containerDetails[0]?.batch_detail?.inhouse_batch_number ||
                      'NA'
                }
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Received Date"
              rules={[
                {
                  required: false,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                value={`${
                  containerDetails[0]?.received_date
                    ? moment(containerDetails[0]?.received_date).format(
                        'DD-MMM-YYYY'
                      )
                    : 'NA'
                }`}
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Quantity"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                placeholder="Enter Quantity"
                disabled={searchParams.get('id') ? isEdit : false}
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="UOM"
              rules={[
                {
                  required: true,
                  message: 'Select Material Code'
                }
              ]}
              className={styles.customLable}
              name="uom"
            >
              <Select
                showSearch
                placeholder="Select UOM"
                optionFilterProp="children"
                disabled={searchParams.get('id') ? true : false}
              >
                <option value="Grams">Grams</option>
                <option value="ml">Milliliters(ML)</option>
                <option value="No.'s">{`No.'s`}</option>
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} xl={8} span={12} md={24} sm={24}>
            <FormItem
              label="Sampling Date"
              name="sampling_date"
              rules={[
                {
                  required: false,
                  message: 'Select Sampling Date'
                }
              ]}
              className={styles.customLable}
            >
              <DatePicker
                defaultValue={moment()}
                format={dateFormat}
                style={{ width: '100%' }}
                disabled
              />
            </FormItem>
          </Col>
          {searchParams.get('id') ? (
            <Col xs={24} xl={24} span={24} md={24} sm={24}>
              <FormItem
                label="Comments"
                name="notes"
                rules={[
                  {
                    required: false,
                    message: 'Enter Ntes'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea
                  value={
                    searchParams.get('id')
                      ? containerDetails[0]?.batch_detail?.notes
                      : ''
                  }
                  rows={4}
                  disabled={searchParams.get('id') ? isEdit : false}
                />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
        </Row>
        <br />
        <FormItem className="text-center">
          {searchParams.get('id') && !isEdit ? (
            <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              // onClick={() => handleQA()}
              type="primary"
            >
              Initiate Verification
            </CustomButton>
          ) : (
            ''
          )}
          {!searchParams.get('id') ? (
            truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton
                className={styles.inwardButton}
                // htmlType="submit"
                onClick={handleAddContainer}
                type="primary"
              >
                Next
              </CustomButton>
            )
          ) : (
            ''
          )}
        </FormItem>
      </Card>
      {openCam ? (
        <QrReader
          constraints={{ facingMode: 'environment' }}
          delay={1000}
          onError={handleError}
          onResult={(result) => handleQrScan(result)}
          // chooseDeviceId={()=>selected}
          style={{ width: '3px', height: '3px' }}
        />
      ) : (
        ''
      )}
      <Card
        title={<span>Sampling Details</span>}
        className={`${styles.cardStyles_approver}`}
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            pagination={false}
            scroll={{ x: 'auto' }}
            // dataSource={pastData}
            dataSource={samplingData}
            rowKey="id"
          />
        </div>
      </Card>
      <br />
      <FormItem className="text-center">
        <Space>
          {' '}
          <CustomButton
            className={styles.inwardButton}
            onClick={() => history.back()}
            type="primary"
          >
            Back to Home
          </CustomButton>
          {isSubmit ? (
            <Spin size="large" />
          ) : (
            <CustomButton
              className={styles.inwardButton}
              // htmlType="submit"
              type="primary"
              onClick={onSubmit}
            >
              Submit
            </CustomButton>
          )}
        </Space>
      </FormItem>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={sampleData}
          setIsModal={setIsModal}
          setIsEdit={setIsEdit}
          page="sampling"
        />
      ) : (
        ''
      )}
    </Form>
  );
};
export default SamplingMaterialsPage;
