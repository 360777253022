import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  Select,
  // Input,
  // Space,
  message,
  // Image,
  Spin
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import {
  createinvoice,
  getAllfgDetails,
  getfgDetails
} from '../../redux/slice';
import { useDispatch } from 'react-redux';
// import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
// import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import CustomTable from 'common/Content/CustomTable';
import { Table, Typography } from 'antd';

const { Text } = Typography;
// import { PlusCircleFilled, PlusCircleOutlined } from '@ant-design/icons';

// const { TextArea } = Input;

const FGBatchReport = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const { batchlogs } = useSelector((state) => state.logs);

  // const [truckShow, setTruckShow] = useState(false);
  const [alllFgDetails, setAlllFgDetails] = useState([]);
  const [search, setSearch] = useState();
  const [alllFgs, setAlllFgs] = useState([]);

  useEffect(() => {
    handleGetAllFGDetails();
  }, [search]);

  const handleGetAllFGDetails = () => {
    const payload = { search: search };
    dispatch(getAllfgDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setAlllFgDetails(response?.payload?.data);
      }
    });
  };

  const onChange = (e) => {
    const payload = { fg_batch_num_id: e };
    dispatch(getfgDetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setAlllFgs(response?.payload?.data);
      }
    });
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onFinish = (values) => {
    // setTruckShow(true);

    dispatch(createinvoice(values)).then((response) => {
      if (response?.payload?.success) {
        // setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/invoice-details-list');
      }
    });
  };

  const columns = [
    {
      title: 'Packing/FG Batch #',
      dataIndex: 'packing_fg_batch',
      key: 'packing_fg_batch',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'BWIP Batch #',
      dataIndex: 'bwip_batch',
      key: 'bwip_batch',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Material Code',
      dataIndex: 'material_code',
      key: 'material_code',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Material Description',
      dataIndex: 'material_description',
      key: 'material_description',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Material Batch No(Internal)',
      dataIndex: 'material_batch',
      key: 'material_batch',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Qty Dispensed',
      dataIndex: 'qty_dispensed',
      key: 'qty_dispensed',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'UOM',
      dataIndex: 'material_uom',
      key: 'material_uom',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'Value Dispensed',
      dataIndex: 'value_dispensed',
      key: 'value_dispensed',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: '% of Blend/ WIP',
      dataIndex: 'percent_blend_wip',
      key: 'percent_blend_wip',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'Actual Value',
      dataIndex: 'actual_value',
      key: 'actual_value',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    }
  ];
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card title="INVOICE DETAILS" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={6} span={6} md={24} sm={24}>
              <FormItem
                label="FG Batch Number"
                name="fg_batch_number"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a FG Batch number"
                  optionFilterProp="children"
                  onChange={(e) => onChange(e, 'id')}
                  onSearch={onSearch}
                >
                  {alllFgDetails ? (
                    alllFgDetails?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.packing_fg_batch}
                        </option>
                      );
                    })
                  ) : (
                    <Spin />
                  )}
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        {/* <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton htmlType="button" onClick={() => history.back()}>
              Cancel
            </CustomButton>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton type="primary" htmlType="submit" loading={loading}>
                Submit
              </CustomButton>
            )}
          </Space>
        </FormItem> */}
      </Form>
      <Card className={`${styles.subpanel}`}>
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            pagination={false}
            scroll={{ x: 'auto' }}
            dataSource={alllFgs}
            summary={(alllFgs) => {
              let totalBorrow = alllFgs[0]?.total_cost_fg;

              // pageData.forEach(({ borrow, repayment }) => {
              //   totalBorrow += borrow;
              // });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={9} align="center">
                      <Text style={{ fontWeight: 'bold' }}>
                        Total Cost of FG
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="success">{totalBorrow}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </Card>
    </Layout>
  );
};
export default FGBatchReport;
