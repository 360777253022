import apiClient from 'utilities/apiClient';

export const batch = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.batch}/${url}`, payload, true);
};

export const createbatch = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.batch}/${url}`, payload, true);
};

export const updatebatch = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.qc}/${url}`, payload, true);
};

export const createRemovedStock = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.remstock}/${url}`, payload, true);
};

export const updateRemovedStock = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.remstock}/${url}`, payload, true);
};

export const getRemovedStock = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.remstock}/${url}`, payload, true);
};

export const approveRemovedStock = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.remstock}/${url}`, payload, true);
};

export const rejectRemovedStock = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.remstock}/${url}`, payload, true);
};

export const getDetailedDispenseData = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const inventory = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.inventory}/${url}`, payload, true);
};
